import Footer from './Footer';
import Header from './Header';
import LayoutGrid from './LayoutGrid';

export default function Layout({ pageId, children }) {
  return (
    <>
      <Header pageId={pageId} />
      <LayoutGrid as="main">{children}</LayoutGrid>
      <Footer />
    </>
  );
}
