import { graphql } from 'gatsby';
import { Box, Heading, Paragraph } from 'theme-ui';
import useMainMenu from '../../useMainMenu';
import Link from '../Link';
import LayoutGrid from './LayoutGrid';

export default function Footer(props) {
  return (
    <LayoutGrid
      as="footer"
      sx={{
        backgroundColor: 'accent',
        color: 'accent.100',
        paddingY: [4, 5],
        gridTemplateRows: 'auto 1fr ',
        gridTemplateAreas: [
          `". logo    logo    logo    logo    logo    logo    ."
           ". contact contact contact contact contact contact ."
           ". office  office  office  office  office  office  ."
           ". menu    menu    menu    menu    menu    menu    ."`,

          `". logo    logo    logo    logo    logo    logo    ."
           ". contact contact contact office  office  office  ."
           ". menu    menu    menu    menu    menu    menu    ."`,

          `". logo    logo    logo    logo    menu    menu    ."
           ". contact contact office  office  menu    menu    ."
           ". .       .       .       .       menu    menu    ."`,
        ],
      }}
      {...props}
    >
      <Logo sx={{ gridArea: 'logo', marginX: [3, 4], marginBottom: 5 }} />
      <Menu sx={{ gridArea: 'menu', marginX: [3, 4] }} />
      <Contact sx={{ gridArea: 'contact', marginX: [3, 4], marginBottom: 5 }} />
      <Office sx={{ gridArea: 'office', marginX: [3, 4], marginBottom: 5 }} />
    </LayoutGrid>
  );
}

function Logo(props) {
  return (
    <Box {...props}>
      <Link to="/" variant="footer" sx={{ variant: 'styles.h2', padding: 0 }}>
        Brigitte Pölzl
      </Link>
      <Paragraph variant="small" sx={{ color: 'accent.300', marginTop: 0 }}>
        Psychotherapeutin ∙ Beraterin ∙ Trainerin
      </Paragraph>
    </Box>
  );
}

function Menu(props) {
  const { items } = useMainMenu();
  return (
    <Box as="nav" {...props}>
      <Heading as="h4" variant="styles.h4" sx={{ marginBottom: 3 }}>
        Seiten
      </Heading>
      {items?.map(({ to, target, target: mainTarget, items }, index) => (
        <Box key={index} sx={{ marginBottom: 4, variant: 'text.small' }}>
          <Link to={to} variant="footer" sx={{ textTransform: 'uppercase' }}>
            {target?.title}
          </Link>
          {items?.map(
            ({ to, target }, index) =>
              target?.id !== mainTarget?.id && (
                <Box key={index} sx={{ marginY: 2 }}>
                  <Link to={to} variant="footer" sx={{ color: 'accent.300' }}>
                    {target?.title}
                  </Link>
                </Box>
              )
          )}
        </Box>
      ))}
    </Box>
  );
}

function Contact(props) {
  return (
    <Box {...props}>
      <Heading as="h4" variant="styles.h4">
        Kontakt
      </Heading>
      <Paragraph variant="small">
        <Link to="tel:+436605772050" variant="footer">
          0660/577 20 50
        </Link>
        <br />
        <Link to="mailto:brigitte.poelzl@kabelplus.at" variant="footer">
          brigitte.poelzl@kabelplus.at
        </Link>
      </Paragraph>
    </Box>
  );
}

function Office(props) {
  return (
    <Box {...props}>
      <Heading as="h4" variant="styles.h4">
        Praxis
      </Heading>
      <Paragraph variant="small">
        Sechshauser Str. 36 Tür 8
        <br />
        1150 Wien
      </Paragraph>
    </Box>
  );
}

export const fragment = graphql`
  fragment Footer on SanityMenuItem {
    target {
      id
      title
    }
  }
`;
