/** @jsxImportSource theme-ui */
import { Link as GatsbyLink } from 'gatsby';
import { Link as ThemedLink } from 'theme-ui';

export default function Link({ variant, ...props }) {
  return (
    <ThemedLink
      as={props.to ? GatsbyLink : 'a'}
      sx={{ variant: variant ? `links.${variant}` : 'styles.a' }}
      {...props}
    />
  );
}
