import { Box, Close, MenuButton } from 'theme-ui';
import useMainMenu from '../../useMainMenu';
import HeaderWrapper from './HeaderWrapper';
import Logo from './Logo';
import MenuItem from './MenuItem';

export default function MobileMenuOverlay({ onClose, ...rest }) {
  return (
    <MobileWrapper sx={{ overflow: 'scroll' }} {...rest}>
      <HeaderWrapper sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
        <Logo />
        <Close
          onClick={onClose}
          sx={{ position: 'absolute', right: 2, top: 2 }}
        />
      </HeaderWrapper>
      <Box sx={{ marginY: 5 }}>
        <MobileMenu />
      </Box>
    </MobileWrapper>
  );
}

MobileMenuOverlay.Toggle = MenuButton;

function MobileWrapper({ children, ...rest }) {
  return (
    <Box
      sx={{
        backgroundColor: 'overlay',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

function MobileMenu(props) {
  const { items } = useMainMenu();
  return (
    <Box as="nav" {...props}>
      {items?.map(({ to, target, target: mainTarget, items }, index) => (
        <Box key={index} sx={{ variant: 'text.small' }}>
          <MenuItem
            to={to}
            sx={{
              display: 'block',
              borderTop: index > 0 ? '2px solid' : undefined,
              borderColor: 'primary',
            }}
          >
            {target?.title}
          </MenuItem>
          {items.length > 0 && (
            <Box sx={{ marginBottom: 2 }}>
              {items?.map(
                ({ to, target }, index) =>
                  target?.id !== mainTarget?.id && (
                    <MenuItem
                      key={index}
                      to={to}
                      sx={{
                        display: 'block',
                        textTransform: 'none',
                        paddingY: 2,
                      }}
                    >
                      {target?.title}
                    </MenuItem>
                  )
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
