import { graphql, useStaticQuery } from 'gatsby';

export default function useMainMenu() {
  const { sanityMenu } = useStaticQuery(query);
  const items = sanityMenu?.items?.map(item => transformItem(item));
  const flattened = items.flatMap(item => {
    if (!item?.items?.length) return item;
    return item.items.map(subItem => ({
      ...subItem,
      items: item.items,
      parent: item,
    }));
  });
  return {
    items,
    getCurrent(pageId) {
      const index = flattened.findIndex(item => item.pageId === pageId);
      if (index === -1) return undefined;
      return flattened[index];
    },
    getNext(pageId) {
      const index = flattened.findIndex(item => item.pageId === pageId);
      if (index === -1) return undefined;
      return flattened[index + 1];
    },
    getPrevious(pageId) {
      const index = flattened.findIndex(item => item.pageId === pageId);
      if (index < 1) return undefined;
      return flattened[index - 1];
    },
    matches(to, pathname, partial) {
      if (pathname === to) return true; // Return exact matches.
      if (pathname.replace(/\/+$/, '') === to) return true; // Allow slashes at the end.
      if (!partial) return false;
      // Search for partial matches in the parents.
      const item = flattened.find(item => item.to.includes(pathname));
      return item?.parent?.to === to;
    },
  };
}

function transformItem(item) {
  return {
    target: item?.target,
    pageId: item?.target?.id,
    title: item?.title || item?.target?.title,
    slug: item?.target?.slug,
    to: to(item?.target?.slug),
    items: item?.items?.map(item => transformItem(item)),
  };
}
function to(slug) {
  if (!slug?.current) return undefined;
  if (slug.current === 'index') return '/';
  return `/${slug.current}`;
}

const query = graphql`
  fragment mainMenu_item on SanityMenuItem {
    title
    target {
      id
      title
      slug {
        current
      }
    }
    ...PageNavigator
    ...Footer
  }

  query mainMenu {
    sanityMenu(menuId: { eq: "main" }) {
      items {
        ...mainMenu_item
        items {
          ...mainMenu_item
        }
      }
    }
  }
`;
