import React from 'react';
import Link from '../Link';

export default function Logo(props) {
  return (
    <Link
      to="/"
      variant="nav"
      sx={{
        fontFamily: 'heading',
        fontWeight: 'heading',
        fontSize: 'large',
        paddingY: 2,
      }}
      {...props}
    >
      Brigitte Pölzl
    </Link>
  );
}
