import { Grid } from 'theme-ui';

export default function LayoutGrid({ children, ...rest }) {
  return (
    <Grid
      gap={0}
      sx={{
        gridTemplateColumns: theme => [
          `
          [full-start content-start text-start left-start middle-start right-start]
          0
          1fr
          1fr
          1fr
          [center]
          1fr
          1fr
          1fr
          0
          [text-end content-end full-end left-end middle-end right-end]
          `,
          null,
          `
          [full-start left-start]
          1fr
          [content-start]
          minmax(0, ${theme.sizes?.column}px)
          [text-start]
          minmax(0, ${theme.sizes?.column}px)
          [left-end middle-start]
          minmax(0, ${theme.sizes?.column}px)
          [center]
          minmax(0, ${theme.sizes?.column}px)
          [middle-end right-start]
          minmax(0, ${theme.sizes?.column}px)
          [text-end]
          minmax(0, ${theme.sizes?.column}px)
          [content-end]
          1fr
          [full-end right-end]
          `,
        ],
      }}
      {...rest}
    >
      {children}
    </Grid>
  );
}
