import { useLocation } from '@gatsbyjs/reach-router';
import React from 'react';
import useMainMenu from '../../useMainMenu';
import Link from '../Link';

export default function MenuItem({
  to,
  children,
  color = 'primary',
  partial,
  ...rest
}) {
  const location = useLocation();
  const { matches } = useMainMenu();
  const isPartiallyCurrent = matches(to, location.pathname, partial);
  return (
    <Link
      to={to}
      variant="mainMenu"
      {...rest}
      sx={
        isPartiallyCurrent
          ? {
              backgroundColor: color,
              color: `${color}.contrast`,
              ':hover': { color: `${color}.contrast` },
            }
          : {}
      }
    >
      {children}
    </Link>
  );
}
