import React from 'react';
import { Box, Flex } from 'theme-ui';
import useMainMenu from '../../useMainMenu';
import MenuItem from './MenuItem';

export default function SubMenu({ pageId, ...rest }) {
  const { getCurrent } = useMainMenu();
  const current = getCurrent(pageId);

  if (!current?.items?.length) return null;
  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }} {...rest}>
      <Box as="nav">
        {current.items.map(({ to, title }) => (
          <MenuItem key={to} to={to} color="primary.200">
            {title}
          </MenuItem>
        ))}
      </Box>
    </Flex>
  );
}
