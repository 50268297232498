import React from 'react';
import { Box } from 'theme-ui';
import useMainMenu from '../../useMainMenu';
import MenuItem from './MenuItem';

export default function MainMenu(props) {
  const { items } = useMainMenu();
  return (
    <Box as="nav" {...props}>
      {items?.map(({ to, title }) => (
        <MenuItem key={to} to={to} partial>
          {title}
        </MenuItem>
      ))}
    </Box>
  );
}
