import React from 'react';
import { Flex } from 'theme-ui';

export default function HeaderWrapper({ children, ...rest }) {
  return (
    <Flex
      as="header"
      sx={{
        backgroundColor: 'header',
        alignItems: 'center',
        borderBottom: 4,
        borderColor: 'primary',
        justifyContent: 'space-between',
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}
