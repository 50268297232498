import React from 'react';
import HeaderWrapper from './HeaderWrapper';
import Logo from './Logo';
import MainMenu from './MainMenu';
import MobileMenuOverlay from './MobileMenuOverlay';
import SubMenu from './SubMenu';

export default function Header({ pageId, ...rest }) {
  const [isOpen, toggle] = React.useState(false);
  return (
    <>
      <HeaderWrapper {...rest}>
        <Logo />
        <MainMenu sx={{ display: ['none', null, null, 'block'] }} />
        <MobileMenuOverlay.Toggle
          onClick={() => toggle(!isOpen)}
          sx={{ display: [null, null, null, 'none'], marginRight: 2 }}
        />
      </HeaderWrapper>

      {isOpen && (
        <MobileMenuOverlay
          onClose={() => toggle(false)}
          sx={{ display: [null, null, null, 'none'] }}
        />
      )}
      <SubMenu pageId={pageId} sx={{ display: ['none', null, null, 'flex'] }} />
    </>
  );
}
